var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('highcharts', {
    staticClass: "map",
    attrs: {
      "constructor-type": 'mapChart',
      "options": _vm.mapOptions
    }
  });
}
var staticRenderFns = []

export { render, staticRenderFns }